<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                app
                clipped
                class="elevation-0"
                :width="expanded ? 300 : 70"
                color="white"
                elevation="0"
                style="border-right: 1px solid #e0e0e0"
        >
            <v-list-item :class="expanded ? 'pl-7' : 'row'" class=" py-8">
                <img src="/image/logo.png" :width="expanded ? 68 : 45" alt="" @click="reload">
            </v-list-item>


            <v-list dense nav>
                <v-list-item-group
                        color="primary"
                        v-model="navigations"
                >
                    <template v-for="(item) in items">
                        <v-list-item
                                class="py-2 px-4 ma-0"
                                :key="item.route"
                                link
                                :to="item.route"
                                :value="item.navigation"
                        >
                            <v-list-item-icon>
                                <v-icon size="24">{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title style="font-size: 16px">{{ item.title }}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </template>
                  <v-list-item
                      class="py-2 px-4 ma-0"
                      link
                      :to="{name: 'chatbot'}"
                  >
                    <v-list-item-icon>
                      <v-icon size="24">mdi-robot</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title style="font-size: 16px">Telegram chatbot</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                    <v-list-item
                            class="py-2 px-4 ma-0"
                            link
                            value="logout"
                            @click="logout_dialog = true"
                    >
                        <v-list-item-icon>
                            <v-icon size="24">mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content v-if="$route.name">
                            <v-list-item-title style="font-size: 16px">{{ $t('basics.logout') }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-dialog width="500" v-model="logout_dialog">
            <v-card elevation="0" rounded="lg">
                <v-card-title>{{ $t('basics.logout') }}</v-card-title>
                <v-card-text>
                  {{ $t('basics.logout_confirm') }}

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="logout_dialog = false" elevation="0">{{ $t('basics.no') }}</v-btn>
                    <v-btn color="error" elevation="0" @click="logout">{{ $t('basics.yes') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "SideBar",
    props: {
        expanded: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            drawer: true,
            logout_dialog: false,
            navigations: null,
            items: [
                {title: this.$t('components.sidebar.info'), icon: "mdi-home", route: "/profile", navigation: 'profile'},
                {title: this.$t('components.sidebar.doctors'), icon: "mdi-doctor", route: "/doctors/", navigation: 'doctors'},
                {title: this.$t('components.sidebar.services'), icon: "mdi-clipboard-pulse", route: "/services/", navigation: 'services'},
                {title: this.$t('components.sidebar.users'), icon: "mdi-account-multiple", route: "/users", navigation: 'users'},
                {title: this.$t('components.sidebar.reports'), icon: "analytics", route: "/statistics", navigation: 'statistics'},
            ],
        };
    },
    watch: {
        '$route'(val) {
            this.navigations = val.meta.navigation
        },
    },
    methods: {
        reload() {
            this.$router.push('/', () => window.location.reload())
        },
        logout() {
            this.$store.dispatch('logout').then((res) => {
                if (res)
                    this.$router.push('/login')
            })
        },
    }
}
</script>


<style scoped>
.text-color {
    color: #7F95A3 !important;
}

.text-size {
    font-size: 18px !important;
    line-height: 15px !important;
    padding: 4px 0 !important;
}

.logout_icon {
    font-size: 30px !important;
    margin: 0 10px 0 0 !important;
    padding: 0 !important;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
}

.logout_icon:hover {
    background: #bbb !important;
    color: white !important;
}

.active {
    background: #01A1B7 !important;
}

.active-color {
    color: white !important;
}

.row {
    display: flex;
    justify-content: center;
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flex-row {
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.active-back {
    background: #01A1B7 !important;
    border-radius: 5px;
}

.mr0 {
    margin-right: 0 !important;
}
</style>